import React from 'react';
import Katex from 'katex';
import 'katex/dist/katex.min.css';

interface IFormulaProps {
	formula: string;
}

export default (props: IFormulaProps) => {
	const { formula } = props;

	return <span
		dangerouslySetInnerHTML={{
			__html: Katex.renderToString(
				formula, { throwOnError: false }
			)
		}}
	>
	</span>;
};
