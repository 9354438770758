import React from 'react';
import { styled } from '@mui/material';

export default (props: React.InputHTMLAttributes<HTMLInputElement>) => {
	return <HiddenInput {...props} />;
};

const HiddenInput = styled('input')({
	clip: 'rect(0 0 0 0)',
	clipPath: 'inset(50%)',
	height: 1,
	overflow: 'hidden',
	position: 'absolute',
	bottom: 0,
	left: 0,
	whiteSpace: 'nowrap',
	width: 1
});
